import React from 'react'
import companyInfo from '../company.json'

const ContactItem = ({ title, address, telephone, email }) => {
    return (
        <div className="teaser-item">
            <div className="teaser-item-bg">
                <h1 className="pos-title">{title}</h1>
                <h2 className="pos-subtitle">{address}</h2>
                <div className="floatbox">
                    <div className="pos-content">
                        <div className="element element-textarea first last">
                            <div>
                                <table className="zebra">
                                    <tbody>
                                    {telephone &&
                                    <tr className="odd">
                                      <td className="bold">Telephone</td>
                                      <td>{telephone}</td>
                                    </tr>
                                    }
                                    {email &&
                                    <tr>
                                      <td className="bold">Email</td>
                                      <td><span id="cloak96596"><a
                                        href={`mailto:${email}`}>{email}</a></span>
                                      </td>
                                    </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Contacts = () => {
    const { address: {street, street2, city, state, ZIP}, email} = companyInfo
    return (
        <div id="main" className="grid-block">
            <div id="maininner" className="grid-box" style={{minHeight: '567px'}}>
                <section id="content" className="grid-block">
                    <div id="yoo-zoo" className="yoo-zoo blog-default blog-default-frontpage">
                        <div className="items items-col-2">
                            <div className="first">
                                <ContactItem
                                    title="Office Address"
                                    address={`${street}, ${street2}, ${city}, ${state}, ${ZIP}`}
                                    email={email}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Contacts