import React from 'react'
import { Link } from 'react-router-dom'
import worldMap from '../assets/images/worldmap.png'
import companyInfo from '../company.json'

const Home = () => {
  const { address: {city, state}} = companyInfo
    return (
        <div>
            <section id="top-a" className="grid-block">
                <div className="grid-box width100 grid-h">
                    <div className="module mod-box  deepest" style={{minHeight: '575px'}}>
                        <div id="slideshow-1-59c91073d2ad9"
                             className="wk-slideshow wk-slideshow-clickbit" data-widgetkit="slideshow"
                             style={{visibility: 'visible', position: 'relative', width: '960px'}}>
                            <div>
                                <ul className="slides"
                                    style={{width: '100%', overflow: 'hidden', position: 'relative', height: '446px'}}>
                                    <li style={{
                                        top: '0px',
                                        left: '0px',
                                        position: 'absolute',
                                        display: 'list-item',
                                        zIndex: '2',
                                        width: '960px',
                                        height: '446px'
                                    }}>
                                        <article className="wk-content clearfix">
                                            <img src={worldMap} alt="worldmap" width="980" height="445"/>
                                        </article>
                                    </li>
                                </ul>
                                <div className="caption" style={{display: 'block'}}>
                                    <div className="quote">
                                        <h1>We are <span>Connecting</span> the world</h1>
                                        <h2><span>one business</span> at a time</h2>
                                    </div>
                                </div>
                                <ul className="captions" style={{display: 'none'}}>
                                    <li>
                                        <div className="quote">
                                            <h1>We are <span>Connecting</span> the world</h1>
                                            <h2><span>one business</span> at a time</h2>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="bottom-a" className="grid-block">
                <div className="grid-box width50 grid-h">
                    <div className="module mod-line  deepest" style={{minHeight: '260px'}}>
                        <h3 className="module-title">Our<span className="color"> Services</span></h3>
                        <ul className="line line-icon">
                            <li><Link to="/services">Software development</Link></li>
                            <li><Link to="/services">Customized development and integration</Link></li>
                            <li><Link to="/services">Software maintenance</Link></li>
                            <li><Link to="/services">Web application design and maintenance</Link></li>
                            <li><Link to="/services">Hosting of website and software</Link></li>
                            <li><Link to="/services">Training packages</Link></li>
                            <li><Link to="/services">IT consulting</Link></li>
                            <li><Link to="/services">Marketing and SEO</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="grid-box width50 grid-h">
                    <div className="module mod-line  deepest" style={{minHeight: '260px'}}>
                        <h3 className="module-title">Office<span className="color">locations</span></h3>
                        <div style={{marginLeft: '20px'}}>
                            <ul>
                                <li>{city}, {state}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Home