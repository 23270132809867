import React from 'react'
import companyInfo from '../company.json'

const Footer = () => {
    const { name } = companyInfo
    const year = new Date().getFullYear()

    return (
        <div id="block-footer">
            <div className="wrapper">
                <footer id="footer">
                    <a id="totop-scroller" href="#page"> </a>
                    <div className="module deepest">
                        <div className="footer1">Copyright © {year} {name}. All Rights Reserved.</div>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Footer