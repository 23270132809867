import React from 'react'
import ServiceItem from '../components/ServiceItem'
import {icon03, icon04, icon06, icon12, icon15, icon16, icon17, icon23, icon24, icon29, icon33, icon34, icon35, icon36, icon37} from '../assets/images/icons'

const Services = () => {
  return (
    <section id="content" className="grid-block">
      <div id="yoo-zoo" className="yoo-zoo blog-default blog-default-frontpage">
        <div className="items items-col-3">
          <ServiceItem
            title="Back office management"
            icon={icon29}
            text="Back office management software accompanies your front end, to generate reports, and manage your end users."
          />
          <ServiceItem
            title="Marketing"
            icon={icon17}
            text="Selling your services or products is not always easy. Let us take care of that for you. We know your application, we know how to present it!"
          />
          <ServiceItem
            title="Software development"
            icon={icon04}
            text="We develop the best software, it is available for all platforms, even smartphones! Ask us about our current license offers!"
          />
          <ServiceItem
            title="Customized development"
            icon={icon03}
            text="You have very specific needs, and no available application suits your need? Look no more! We are your solution!"
          />
          <ServiceItem
            title="Mobile web optimization"
            icon={icon12}
            text="Your website or application will be completely accessible through mobile devices, optimized for small displays."
          />
          <ServiceItem
            title="Software integration"
            icon={icon06}
            text="Already have someone else's software running? Want to add ours without the complication of multiple backends? You're in the right place!"
          />
          <ServiceItem
            title="Web application design"
            icon={icon24}
            text="Instantly accessible from anywhere, web applications are one of our specialties. No download necessary!"
          />
          <ServiceItem
            title="IT consulting"
            icon={icon16}
            text="Our team is comprised of experts in a very wide range of hardware and software. Ask us about IT!"
          />
          <ServiceItem
            title="SEO"
            icon={icon23}
            text="Search Engine Optimization is the key to being found on the web. Your users will thank you, and your bottom line will improve!"
          />
          <ServiceItem
            title="Software maintenance"
            icon={icon15}
            text="Operating systems evolve, browser applications change, and so our software needs to adapt. Software maintenance is one of our specialties!"
          />
          <ServiceItem
            title="Server and cloud hosting"
            icon={icon37}
            text="Dedicated servers or shared resources? We provide ready to use solutions for all your application and data storage needs."
          />
          <ServiceItem
            title="Server monitoring"
            icon={icon36}
            text="This service allows for business and IT data to be merged into a single source of information, to identify failure and help business decisions."
          />
          <ServiceItem
            title="Call center support"
            icon={icon35}
            text="Our support team will solve your customers' issues or concerns, 24/7, in multiple languages. This service is both inbound and outbound."
          />
          <ServiceItem
            title="VOIP solutions"
            icon={icon34}
            text="Our VoIP specialists will craft the perfect global communications package for your business."
          />
          <ServiceItem
            title="Telecom services"
            icon={icon33}
            text="Around the world, long distance calling services, at low per minute rates, for both domestic and international calls."
          />
        </div>
      </div>
    </section>
  )
}

export default Services